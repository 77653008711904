import axios from "axios";

export const getIndexes = async () => {
    try {
        const res = await axios.get(`/interface/api/stock_index/moves/list?group=1`);

        return res.data;
    } catch (err) {
        throw err;
    }
};
